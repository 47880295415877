.scene-window {
    grid-column-start: start;
    grid-column-end: end;
    grid-row-start: start;
    grid-row-end: end;

    display: grid;
    grid-template-columns: repeat(24, 45px);
    grid-template-rows: repeat(16, 45px);

    width: 100%;
}

.scene-image, .scene-image-transitioning {
    border: 1px solid lightgray;
    background-size: fill;
    transition: transform 0.8s ease-in-out;
}

.scene-text, .scene-text-transitioning {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    background-color: black;
    border: 1px solid lightgray;
    transition: transform 0.8s ease-in-out;
    transform: translateX(0px);
}

.scene-text p,
.scene-text-transitioning p {
    font-size: 20px;
    font-family: 'pixel_operator_monoregular', "Andale Mono", Arial, sans-serif;
    margin: 0px;
    line-height: 25px;
}

/* SCENE 0 */
#scene-0 .scene-image,
#scene-0 .scene-image-transitioning {
    grid-column-start: 3;
    grid-row-start: 3;
    width: 940px;
    height: 500px;
    background-image: url(../../assets/scenes/scene-0.png);
    background-position: top;
}
#scene-0 .scene-image-transitioning {
    transform: translateX(-1800px);
}

#scene-0 .scene-text,
#scene-0 .scene-text-transitioning {
    grid-column-start: 2;
    grid-row-start: 2;
    width: 500px;
    height: 160px;
}
#scene-0 .scene-text-transitioning {
    transform: translateX(1800px);
}

/* SCENE 1 */
#scene-1 .scene-image,
#scene-1 .scene-image-transitioning { 
    grid-column-start: 3;
    grid-row-start: 2;
    width: 651px;
    height: 551px;
    background-image: url(../../assets/scenes/scene-1.png);
    background-position: center;
}
#scene-1 .scene-image-transitioning {
    transform: translateX(1800px);
}

#scene-1 .scene-text,
#scene-1 .scene-text-transitioning {
    width: 300px;
    height: 280px;
    grid-column-start: 15;
    grid-row-start: 6;
}
#scene-1 .scene-text-transitioning {
    transform: translateX(-1800px);
}

/* SCENE 2 */
#scene-2 .scene-image,
#scene-2 .scene-image-transitioning {
    grid-column-start: 2;
    grid-row-start: 2;
    width: 820px;
    height: 501px;
    background-image: url(../../assets/scenes/scene-2.png);
    background-position: center;
}
#scene-2 .scene-image-transitioning {
    transform: translateX(1800px);
}

#scene-2 .scene-text,
#scene-2 .scene-text-transitioning {
    width: 460px;
    height: 240px;
    grid-column-start: 12;
    grid-row-start: 8;
}
#scene-2 .scene-text-transitioning {
    transform: translateX(-1800px);
}

/* BUTTONS */
.nav-button-wrapper {
    grid-column-start: 18;
    grid-row-start: 15;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 225px;
    height: 50px;
}

#next-button,
#next-button-clicked {
    width: 100px;
    height: 50px;
    background-image: url(../../assets/button/nav-button-up.png);
    background-size: contain;
    outline: none;
    border: none;
    font-size: 18px;
    font-family: 'pixel_operator_monoregular', "Andale Mono", Arial, sans-serif;
    transition: transform 0.8s ease-in-out;
}
#next-button-clicked {
    background-image: url(../../assets/button/nav-button-down.png);
    font-size: 17px;
}
#next-button.show {
    transform: translateY(0px);
}
#next-button.hide {
    transform: translateY(1080px);
}

#back-button,
#back-button-clicked {
    width: 100px;
    height: 50px;
    background-image: url(../../assets/button/nav-button-up.png);
    background-size: contain;
    outline: none;
    border: none;
    margin-right: 25px;
    font-size: 18px;
    font-family: 'pixel_operator_monoregular', "Andale Mono", Arial, sans-serif;
    transition: transform 0.8s ease-in-out;
}
#back-button-clicked {
    background-image: url(../../assets/button/nav-button-down.png);
    font-size: 17px;
}
#back-button.show {
    transform: translateY(0px);
}
#back-button.hide {
    transform: translateY(1080px);
}