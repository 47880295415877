.method-dock {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100px;
    height: 38px;
    margin: 5px;
    
    background-image: url('../assets/tile-svgs/method-dock.svg');
}

.path-dock {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 162px;
    height: 38px;
    margin-right: 5px;

    background-image: url('../assets/tile-svgs/path-dock.svg');
}

.key-dock {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 128px;
    height: 38px;
    margin: 5px;

    background-color: #333333;
}

.item-dock {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 128px;
    height: 38px;
    margin: 5px;

    background-color: #333333;
}