@keyframes keys-intro {
    0% { transform: translateY(1080px); }
    100% { transform: translateY(0px); }
}

.key-container, .key-container-outro {
    display: flex;
    flex-direction: column;
    grid-column-start: left-mod-start;
    grid-column-end: left-mod-end;
    grid-row-start: lower-mod-start;
    grid-row-end: lower-mod-end;

    width: 100%;
    background-color: black;
    border: lightgray solid 1px;
    animation: keys-intro 2.75s ease-out 1;
    transition: transform 2.25s ease-out;
}

.key-container-outro {
    transform: translateY(1080px);
}

.key-harbor {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;

    padding: 15px;
    /* border: chartreuse dashed 1px; */
}

.key-harbor p {
    margin: auto;
}