@keyframes methods-intro {
    0% { transform: translateY(1080px); }
    100% { transform: translateY(0px); }
}

.method-container, .method-container-outro {
    display: flex;
    flex-direction: column;
    grid-column-start: left-mod-start;
    grid-column-end: left-mod-end;
    grid-row-start: center-mod-start;
    grid-row-end: center-mod-end;

    width: 100%;
    background-color: black;
    border: lightgray solid 1px;

    animation: methods-intro 2.25s ease-out 1;
    transition: transform 2.75s ease-out;
}

.method-container-outro {
    transform: translateY(1080px);
}

.method-harbor {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;

    justify-content: flex-start;

    padding: 8px;
}