@keyframes story-intro {
    0% { transform: translateX(-1600px); }
    100% { transform: translateX(0px); }
}

/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
    width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #00000000;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(211, 211, 211, 0.466); 
    border-radius: 2px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(211, 211, 211, 0.366);
}

.story-container, .story-container-outro {
    grid-column-start: left-mod-start;
    grid-column-end: right-mod-end;
    grid-row-start: upper-mod-start;
    grid-row-end: upper-mod-end;

    display: grid;
    grid-template-columns: 50px [start] 1fr [end] 50px;
    grid-template-rows: 8% [start-objective] 5% [start-name] 8%
                        [start-description] 2fr [start-parent-poi] 1fr [req-bar-top] 70px
                        [req-bar-bottom] 10%;

    width: 100%;
    background-color: black;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;

    animation: story-intro 1.25s ease-in-out 1;
    transition: transform 1.25s ease-in-out;
}

.story-container-outro {
    transform: translateX(-1600px);
}

.story-header-box {
    grid-column-start: start;
    grid-column-end: end;
    grid-row-start: start-objective;

    height: 40px;

    overflow-x: hidden;

    background-image: url(../../assets/fills/dark-diag-fill.png);
}

.story-header {
    grid-column-start: start;
    grid-column-end: end;
    grid-row-start: start-objective;

    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: transform 2s ease;
    transform: translateX(0px);

    padding: 10px 10px;
    
    background-color: #FFFFFF;
}

p.objective {
    grid-column-start: start;
    grid-column-end: end;
    grid-row-start: start-objective;
    grid-row-end: start-name;

    margin: 0px;
    color: black;
    text-align: right;
}



h3.poi-name {
    grid-column-start: start;
    grid-column-end: end;
    grid-row-start: start-name;
    grid-row-end: start-description;

    color: black;
    font-size: 24px;
    font-family: 'pixel_operator_monobold';
    margin: 0px;
}

.description-container {
    display: flex;
    flex-wrap: wrap;

    grid-column-start: start;
    grid-column-end: end;
    grid-row-start: start-description;
    grid-row-end: start-help-text;

    height: max-content;
    max-height: 120px;
    line-height: 40px;
    overflow-y: scroll;

    transition: opacity 1s ease-out;
}

.description-container .word {
    margin-right: 5px;
    height: max-content;
}

.parent-poi-container {
    display: flex;

    grid-column-start: start;
    grid-column-end: end;
    grid-row-start: start-parent-poi;
    grid-row-end: end-help-text;

    line-height: 40px;
    height: 60px;
    padding-top: 10px;

    transition: opacity 1s ease-out;
}

.parent-poi-container .word {
    margin-right: 5px;
    height: max-content;
}

.word-item {
    font-family: 'pixel_operator_monobold';
    margin-right: 5px;
    height: max-content;
}

.request-feedback {
    display: flex;
    justify-content: center;

    margin-top: 10px;

    grid-column-start: start;
    grid-column-end: end;
    grid-row-start: req-bar-bottom;

}

.request-feedback p {
    margin: 0px;
}