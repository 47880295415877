body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(./assets/fills/grey-dot-dark-open-graph-fill.svg);
  background-position: center;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'pixel_operator_monobold', "Andale Mono", Arial, sans-serif;
  font-size: 96px;
  color: whitesmoke;
  text-align: center;
  margin: 30px;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}