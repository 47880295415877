@keyframes items-intro {
    0% { transform: translateX(1200px); }
    100% { transform: translateX(0px); }
}

.item-container, .item-container-outro {
    display: flex;
    flex-direction: column;
    grid-column-start: mid-mod-start;
    grid-column-end: mid-mod-end;
    grid-row-start: center-mod-start;
    grid-row-end: lower-mod-end;

    width: 100%;
    background-color: black;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    animation: items-intro 1.75s ease-in-out 1;
    transition: transform 2.25s ease-in-out;
}

.item-container-outro {
    transform: translateX(1200px);
}

.item-harbor {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;

    padding: 15px;
}