.App-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  display: grid;
  grid-template-columns: [start] 20px [left-mod-start] 1fr [left-mod-end] 20px
                         [mid-mod-start] .9fr [mid-mod-end] 20px
                         [right-mod-start] 2.1fr [right-mod-end] 20px [end];

  grid-template-rows: [start] 20px [upper-mod-start] 5fr [upper-mod-end] 20px
                      [center-mod-start] 1.65fr [center-mod-end] 20px
                      [lower-mod-start] 1.65fr [lower-mod-end] 20px [end];

  width: 1080px;
  height: 720px;
  margin: auto;

  background-position: center bottom -4px;

  font-family: 'pixel_operator_monoregular', "Andale Mono", Arial, sans-serif;
  color: white;
  text-align: left;
}

/* Global Styling */

.title-bar {
  border-bottom: lightgray solid 1px;
}

.title-bar h4 {
  color: lightgray;
  margin: 0px 0px 2px 8px;
}

@keyframes node-blink { to { background-image: none; } }
@keyframes blink { to { visibility: hidden; } }
@keyframes orbit { to { stroke-dashoffset: 8px; } }

/* Mobile View */
.mobile-view-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mobile-view {
  width: 50%;
  padding: 25px;
  background-color: black;
  border: 1px solid lightgray;
  margin-top: 30%;
}

.mobile-view p {
  font-family: 'pixel_operator_monobold', "Andale Mono", Arial, sans-serif;
  color: white;
  font-size: 18px;
  text-align: center;
  margin: auto;
}