:root {
    --default-tile-color: #70c5b3;
}

/* METHOD TILES */
.method-tile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100px;
    height: 40px;
}

.method-tile-svg {
    height: 40px;
}

.method-tile h3 {
    position: absolute;
    margin: auto;
    font-size: medium;
    color: black;
}

/* PATH TILES */
.path-tile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 163px;
    height: 40px;
}

.path-tile-svg {
    height: 40px;
}

.path-tile h3 {
    position: absolute;
    margin: auto;
    font-size: medium;
    color: black;
}

/* KEY TILES */
.key-tile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 130px;
    height: 40px;
}

.key-tile-svg {
    height: 40px;
}

.key-tile h3 {
    position: absolute;
    margin: auto;
    font-size: medium;
    color: black;
}

/* ITEM TILES*/
.item-tile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 130px;
    height: 38px;
}

.item-tile-svg {
    height: 40px;
}

.item-tile h3 {
    position: absolute;
    margin: auto;
    font-size: medium;
    color: black;
}