.request-container {
    grid-column-start: start;
    grid-column-end: end;
    grid-row-start: req-bar-top;
    grid-row-end: req-bar-bottom;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 10px;

    background-image: url(../assets/fills/dark-large-grid-fill.png);

    border-radius: 3px;
}

.request-button {
    display: flex;
    justify-content: center;
    align-items: center;

    transform: translateX(-.5px);
    width: 166px;
    height: 55px;
    background-image: url(../assets/button/req-button-unpressed.png);
    background-position: center;
    background-size: cover;
    
    margin-right: 3px;
    border: none;
    outline: black solid 1px;
}

.request-button-clicked {
    display: flex;
    justify-content: center;
    align-items: center;

    transform: translateX(-.5px);
    width: 166px;
    height: 55px;
    background-image: url(../assets/button/req-button-pressed-alt1.png);
    background-position: center;
    background-size: cover;
    
    margin-right: 3px;
    border: none;
    outline: black solid 1px;
}

.request-button h1 {
    font-family: 'pixel_operator_monoregular', "Andale Mono", Arial, sans-serif;
    color: black;
    font-size: 18px;
    margin: 0px;
}

.request-button-clicked h1 {
    color: rgb(41, 41, 41);
    font-size: 17px;
    margin: 0px;
}

button:focus {
    outline: black solid 1px;
}

.tile-receiver-zone {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 90%;
    width: auto;
}

.receiver-text {
    position: absolute;
    font-size: medium;
    color: #C8C8C8;
    margin: auto;
}

/* METHOD RECEIVER */
.method-receiver {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    width: 113px;
    margin-left: 2px;
    margin-right: 5px;
}

.method-receiver-svg, .hovered-method-receiver-svg {
    position: absolute;
}

.hovered-method-receiver-svg path {
    fill: #7a7d85;
}

.method-receiver .outline-svg {
    position: absolute;
    transform: translateX(-1px);
}

.method-receiver .receiver-text {
    transform: translateX(-2px);
}

/* PATH RECEIVER */
.path-receiver {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    width: 178px;
    margin-right: 20px;
}

.path-receiver-svg, .hovered-path-receiver-svg {
    position: absolute;
}

.hovered-path-receiver-svg path {
    fill: #7a7d85
}

.path-receiver .outline-svg {
    position: absolute;
    transform: translateX(2px);
}

.path-receiver .receiver-text {
    transform: translateX(4px);
}

.path-receiver .path-tile {
    transform: translateX(1px);
}

/* KEY RECEIVER */
.key-receiver {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    min-width: 146px;

    margin-right: 20px;
}

.key-receiver .outline-svg {
    position: absolute;
}

/* ITEM RECEIVER */
.item-receiver {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    min-width: 146px;

    margin-right: 20px;
}

.rect-receiver-svg, .hovered-rect-receiver-svg {
    position: absolute;
}

.hovered-rect-receiver-svg path {
    fill: #7a7d85;
}

.item-receiver .outline-svg {
    position: absolute;
}