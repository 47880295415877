.start-menu, .start-menu-transitioning {
    grid-column-start: start;
    grid-row-start: start;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 1000px;
    height: 480px;
    margin: 120px 40px 120px 40px;

    border: 1px solid lightgray;
    background-color: black;
    /* transition: transform 0.6s ease-in;
    transform: translateX(0px); */
}

.start-menu h1, .start-menu-transitioning h1 {
    font-family: 'pixel_operator_monobold', "Andale Mono", Arial, sans-serif;
    font-size: 72px;
    color: whitesmoke;
    text-align: center;
    margin: 20px;
}

.start-menu p, .start-menu-transitioning p {
    font-family: 'pixel_operator_monoregular', "Andale Mono", Arial, sans-serif;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 30px;
}

#start-button {
    width: 189px;
    height: 63px;

    background-image: url(../../assets/button/req-button-unpressed.png);
    background-size: contain;
    background-repeat: no-repeat;

    outline: none;
    border: none;

    margin: 40px;
}

#start-button-clicked {
    width: 189px;
    height: 63px;

    background-image: url(../../assets/button/req-button-pressed-alt1.png);
    background-size: contain;
    background-repeat: no-repeat;

    outline: none;
    border: none;

    margin: 40px;
}


#start-button p {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'pixel_operator_monobold', "Andale Mono", Arial, sans-serif;
    font-size: 24px;
    background-color: none;

    margin: 0px;
}

#start-button-clicked p {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'pixel_operator_monobold', "Andale Mono", Arial, sans-serif;
    font-size: 23px;
    color: rgb(41, 41, 41);

    margin: 0px;
}